html {
  height: 100%;
}

body {
  background-color: '#ff352d';
  height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
}
body #root {
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 100%;
}

@media (min-height: 770px) and (min-width: 650px) {
  /* body #root {
    background-image: url('/images/fondo-general.jpg');
  } */
  body #layout {
    border-color: rgb(158, 54, 103);
    box-shadow: 0px 4px 15px 5px;
    border-radius: 5px;
    /* border-style: solid;
    border-width: 1px; */
  }
}

html,
body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

body {
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

/* Fonts */
@font-face {
  font-family: SantanderLogo-Regular;
  font-style: normal;
  font-weight: 400;
  src: url(https://brand.santander.com/Assets/Fonts/SantanderLogo-Regular.woff2)
      format('woff2'),
    url(https://brand.santander.com/Assets/Fonts/SantanderLogo-Regular.woff)
      format('woff');
}
@font-face {
  font-family: SantanderLogo-Light;
  font-style: normal;
  font-weight: 300;
  src: url(https://brand.santander.com/Assets/Fonts/SantanderLogo-Light.woff2)
      format('woff2'),
    url(https://brand.santander.com/Assets/Fonts/SantanderLogo-Light.woff)
      format('woff');
}

/* NEW FONTS */
@font-face {
  font-family: 'SantanderHeadlineRegular';
  src: url('/fonts/SantanderHeadlineW05-Rg.eot?#iefix');
  src: url('/fonts/SantanderHeadlineW05-Rg.eot?#iefix') format('eot'),
    url('/fonts/SantanderHeadlineW05-Rg.woff2') format('woff2'),
    url('/fonts/SantanderHeadlineW05-Rg.woff') format('woff'),
    url('/fonts/SantanderHeadlineW05-Rg.ttf') format('truetype');
}

@font-face {
  font-family: 'SantanderHeadlineBold';
  src: url('/fonts/SantanderHeadlineW05-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'SantanderTextLight';
  src: url('/fonts/SantanderTextW05-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'SantanderTextRegular';
  src: url('/fonts/SantanderTextW05-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'SantanderTextItalic';
  src: url('/fonts/SantanderText-Italic.ttf') format('truetype');
}

/* Fix ---------------------------------------------------------------------*/

/* IMPORTANTE: Fix Safari height on flex */
div {
  flex-shrink: 0;
}

button:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
